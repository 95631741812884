import React from "react";

export const Error = () => {
  return <div className="error mt-10">
    <p className="text-xl">
      Вас ждут встречи, дегустации, прогулки и множество других увлекательных событий, связанных с культурой Франции!{" "}
      <a
        href="/"
        className="text-blue-600 font-bold hover:underline hover:text-blue-700"
      >
        Загляните на главную страницу YanZone
      </a> и откройте для себя что-то новое!
    </p>
  </div>;
};
